import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'

export default function Terms({ data }) {
  const page = data.wpPage
  const { post } = page.template.terms

  return (
    <Layout
      bodyClassName='page page-id-237 terms-of-use-gps-e app-data index-data singular-data page-data page-237-data page-terms-of-use-gps-e-data'
      page={page}
    >
      {post && <Post content={post} />}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      template {
        ... on WpTemplate_Terms {
          terms {
            post {
              body
            }
          }
        }
      }
    }
  }
`

const Post = ({ content }) => {
  const { body } = content
  return (
    <div className='page-default'>
      <div
        className='page-default__content'
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </div>
  )
}
